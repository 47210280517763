import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyDl5sK1iD7MUy3oZEJHWi0u4V-l5zFiPUY",
    authDomain: "hub-construbank.firebaseapp.com",
    projectId: "hub-construbank",
    storageBucket: "hub-construbank.appspot.com",
    messagingSenderId: "500284304821",
    appId: "1:500284304821:web:1fb935d1f77af6ef3ebdfc",
    databaseURL: "https://hub-construbank-default-rtdb.firebaseio.com",
};

const app = initializeApp(firebaseConfig);

export default app
