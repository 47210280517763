import { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ResetPasswordForm = () => {
  const [email, setEmail] = useState("");
  const auth = getAuth();
  const history = useHistory();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log("Email enviado para redefinição de senha");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" color="primary">
            Confirme o email para redefinir a senha
          </Typography>
          <TextField
            type="email"
            label="Email"
            variant="outlined"
            value={email}
            onChange={handleEmailChange}
            sx={{
              marginTop: 1,
            }}
            fullWidth
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            sx={{ marginTop: 2 }}
          >
            Enviar
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{ marginTop: 1.5 }}
            onClick={() => history.push("/")}
          >
            Voltar
          </Button>
        </form>
      </Box>
    </>
  );
};

export default ResetPasswordForm;
