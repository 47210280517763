import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import app from "../Services/Firebase/firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useHistory } from "react-router-dom";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth(app);
  const history = useHistory(); // obtenha o objeto "history" do React Router

  const handleLogin = () => {
    // Faça a validação dos campos de login e faça login no Firebase
    // Exemplo:

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        alert("Login bem-sucedido!");
        history.push("/");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "600px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <TextField
          label="Senha"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <Button variant="contained" onClick={handleLogin}>
          Entrar
        </Button>
        <Button
          variant="outlined"
          onClick={() => history.push("/reset-password")}
          sx={{ marginTop: 1.5 }}
        >
          Esqueci minha senha
        </Button>
      </Box>
    </Box>
  );
};

export default LoginForm;
