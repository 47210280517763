import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuth } from "firebase/auth";
import app from "../Services/Firebase/firebase";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const [user, setUser] = useState(null);
  const [authCompleted, setAuthCompleted] = useState(false);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setAuthCompleted(true);
    });

    return unsubscribe;
  }, []);

  if (!authCompleted) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/login-and-register" />
      }
    />
  );
};

export default PrivateRoute;
