import { Box } from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../Components/ResponsiveAppbar";
import SearchCompanies from "./SearchCompanies";

function EditarCedentes() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
      }}
    >
      <ResponsiveAppBar />
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SearchCompanies />
      </Box>
    </Box>
  );
}

export default EditarCedentes;
