import React, { useState } from "react";
import ResponsiveAppBar from "../../Components/ResponsiveAppbar";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import {
  Email,
  Explore,
  LocationCity,
  Phone,
  Place,
  SaveAltRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Person from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Divider from "@mui/material/Divider";
import ProfilePhoto from "../../Assets/profile_img.jpg";
import SearchIcon from "@mui/icons-material/Search";

import axios from "axios";

const gradientStyle = {
  width: "23%",
  height: "100%",
  background:
    "linear-gradient(0deg, #1976d2, rgba(25,118,210,0.8), rgba(25,118,210,0.6), rgba(25,118,210,0.4), rgba(25,118,210,0.2))",
};

const Conta = () => {
  const [nome, setNome] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [numero, setNumero] = useState();
  const [numeroAlternativo, setNumeroAlternativo] = useState("");
  const [email, setEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isValidAlternativeNumber, setIsValidAlternativeNumber] =
    useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setIsValid(value === password);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const handleConfirmPasswordClick = () => {
    setShowConfirm(!showConfirm);
  };

  const [cep, setCep] = useState("");
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState({
    logradouro: "",
    complemento: "",
    bairro: "",
    localidade: "",
    uf: "",
  });
  const menus = ["conta", "senha"];

  const handleCepChange = (event) => {
    setCep(event.target.value);
  };

  const handleSearchClick = () => {
    axios.get(`https://viacep.com.br/ws/${cep}/json/`).then((response) => {
      setAddress(response.data);
    });
  };

  const handleNextStep = (menu_choice) => {
    if (menu_choice === "conta") {
      console.log(step);
      setStep(1);
    }
    if (menu_choice === "senha") {
      setStep(2);
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[0-9]{1,11}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidEmailAddr = (email_addr) => {
    const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return emailRegex.test(email_addr);
  };

  const validationEmail = (event) => {
    const email_addr = event.target.value;
    setIsValidEmail(isValidEmailAddr(email_addr));

    if (isValidEmailAddr) {
      setEmail(email_addr);
    }
  };

  const validationNumber = (event) => {
    const phoneNumber = event.target.value;
    setIsValidNumber(isValidPhoneNumber(phoneNumber));

    if (isValidPhoneNumber) {
      setNumero(phoneNumber);
    }
  };

  const validationAlternativeNumber = (event) => {
    const phoneNumber = event.target.value;
    setIsValidAlternativeNumber(isValidPhoneNumber(phoneNumber));

    if (isValidPhoneNumber) {
      setNumeroAlternativo(phoneNumber);
    }
  };

  return (
    <div>
      <Box>
        <ResponsiveAppBar />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          backgroundColor: "#f5f3f4",
          marginTop: 0, // Remover o espaçamento superior
          paddingTop: 5, // Remover o espaçamento superior
        }}
      >
        <Box sx={{ width: "80%", height: "450px", margin: "auto" }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              height: "100%",
              backgroundColor: "#ffff",
              margin: "auto",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
              display: "flex",
              padding: 0,
            }}
          >
            <Box sx={gradientStyle}>
              <Box
                sx={{
                  width: "100%",
                  height: "30%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ProfilePhoto}
                    style={{ width: "100%", borderRadius: "100%" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "55%",
                }}
              >
                <List>
                  <ListItem
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => handleNextStep(menus[0])}
                  >
                    <ListItemIcon
                      style={{
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <AccountCircleIcon />
                    </ListItemIcon>
                    <span style={{ color: "#fff" }}>Conta</span>
                  </ListItem>
                  <Divider
                    style={{
                      width: "90%",
                      margin: "auto",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                  />
                  <ListItem
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => handleNextStep(menus[1])}
                  >
                    <ListItemIcon
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <LockIcon />
                    </ListItemIcon>
                    <span style={{ color: "#fff" }}>Senha</span>
                  </ListItem>
                </List>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "15%",
                }}
              >
                <List>
                  <Divider
                    style={{
                      width: "90%",
                      margin: "auto",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                  />

                  <ListItem
                    onClick={() =>
                      console.log(
                        `
                                        Nome: ${nome}
                                        Email: ${email}
                                        Telefone: ${numero}
                                        Telefone Alternativo: ${numeroAlternativo}
                                        `
                      )
                    }
                    sx={{
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.5)",
                      },
                      cursor: "pointer",
                    }}
                  >
                    <ListItemIcon
                      style={{
                        width: 20,
                        color: "#fff",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <SaveAltRounded />
                    </ListItemIcon>
                    <span style={{ color: "#fff" }}>Salvar alterações</span>
                  </ListItem>
                </List>
              </Box>
            </Box>
            <Box
              sx={{
                width: "72%",
                padding: 5,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {step === 1 ? (
                <>
                  <Box sx={{ marginBottom: 1.5 }}>
                    <Typography
                      variant="h7"
                      fontWeight={"600"}
                      color={"primary"}
                      sx={{ padding: 2 }}
                    >
                      Informações cadastrais
                    </Typography>
                    <Box style={{ margintTop: 2 }}>
                      <TextField
                        onChange={(event) => setNome(event.target.value)}
                        required
                        label="Nome Completo"
                        variant="outlined"
                        size="small"
                        sx={{
                          margin: 2,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Person />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        required
                        label="Email"
                        variant="outlined"
                        size="small"
                        error={!isValidEmail}
                        helperText={!isValidEmail && "Digite um Email Válido"}
                        onChange={(event) => validationEmail(event)}
                        sx={{
                          margin: 2,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Email />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box>
                      <TextField
                        required
                        label="Contato"
                        variant="outlined"
                        size="small"
                        sx={{
                          margin: 2,
                        }}
                        error={!isValidNumber}
                        helperText={!isValidNumber && "Digite apenas números"}
                        onChange={(event) => validationNumber(event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{
                          inputMode: "email",
                          maxLength: 11,
                        }}
                      />
                      <TextField
                        label="Contato Alternativo"
                        variant="outlined"
                        size="small"
                        error={!isValidAlternativeNumber}
                        helperText={
                          !isValidAlternativeNumber && "Digite apenas números"
                        }
                        onChange={(event) => validationAlternativeNumber(event)}
                        sx={{
                          margin: 2,
                        }}
                        InputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 11,
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ maxLength: 11 }}
                      />
                    </Box>
                  </Box>
                  <Divider
                    style={{
                      width: "90%",
                      margin: "0",
                      backgroundColor: "rgba(255, 255, 255, 0.9)",
                    }}
                  />

                  <Box sx={{ marginTop: 2 }}>
                    <Typography
                      variant="h7"
                      fontWeight={"600"}
                      color={"primary"}
                      sx={{ padding: 2 }}
                    >
                      Endereço (opcional)
                    </Typography>
                    <Box style={{ margintTop: 1.5 }}>
                      <TextField
                        size="small"
                        variant="standard"
                        label="CEP"
                        value={cep}
                        onChange={handleCepChange}
                        sx={{
                          margin: 2,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <SearchIcon onClick={handleSearchClick} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        size="small"
                        variant="standard"
                        label="Endereço"
                        value={address.logradouro}
                        sx={{
                          margin: 2,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Place />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    <Box>
                      <TextField
                        size="small"
                        variant="standard"
                        label="Bairro"
                        value={address.bairro}
                        sx={{
                          margin: 2,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Explore />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        size="small"
                        variant="standard"
                        label="Cidade"
                        value={address.localidade}
                        sx={{
                          margin: 2,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationCity />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box sx={{ marginBottom: 1.5 }}>
                    <Typography
                      variant="h7"
                      fontWeight={"600"}
                      color={"primary"}
                      sx={{ padding: 0 }}
                    >
                      Alteração de Senha
                    </Typography>
                    <Box
                      style={{
                        marginTop: 2,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <TextField
                        label="Senha"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        size="small"
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ marginTop: 2, width: 450 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleShowPasswordClick}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Confirmar senha"
                        type={showConfirm ? "text" : "password"}
                        value={confirmPassword}
                        size="small"
                        onChange={handleConfirmPasswordChange}
                        error={!isValid}
                        helperText={!isValid && "As senhas não coincidem."}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleConfirmPasswordClick}
                                edge="end"
                              >
                                {showConfirm ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{ marginTop: 2, width: 450 }}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Conta;
