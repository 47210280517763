import React, { useState } from "react";
import { getDatabase, ref, child, get, remove } from "firebase/database";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  getStorage,
  ref as storageREF,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import ResponsiveAppBar from "../../../Components/ResponsiveAppbar";
import { Delete } from "@mui/icons-material";
import AdicionarArquivos from "../../../Components/AdicionarArquivos";

const ListarOperacoes_REFACT = () => {
  const [operacaoEncontrada, setOperacaoEncontrada] = useState(null);
  const [operacaoId, setOperacaoId] = useState(null);
  const [numero, setNumero] = useState("");
  const [openModalAdicionarArquivos, setOpenModalAdicionarArquivos] =
    useState(false);

  const [loading, setLoading] = useState(false);

  const searchOperation = async () => {
    const database = getDatabase();
    const operacoesRef = ref(database, "operacoes/");
    setOperacaoEncontrada(null);
    setLoading(true);

    // Pesquisa em "duplicatas"
    const duplicatasSnapshot = await get(child(operacoesRef, "duplicatas"));
    if (duplicatasSnapshot.exists()) {
      const duplicatas = duplicatasSnapshot.val();
      const operacoes = Object.values(duplicatas);

      for (const operacao of operacoes) {
        const meses = Object.values(operacao);

        for (const mes of meses) {
          const dias = Object.values(mes);

          for (const dia of dias) {
            const operacoesDoDia = Object.entries(dia);

            for (const [operacaoId, operacaoDoDia] of operacoesDoDia) {
              if (operacaoDoDia.numero_da_operacao === numero) {
                const notasFiscais = [];
                const notasPromissorias = [];
                const contratos = [];
                const comprovantesTED = [];
                const canhotos = [];
                const informes = [];
                const storage = getStorage();

                const nfREF = storageREF(
                  storage,
                  `duplicatas/${operacaoDoDia.data}/${operacaoId}/notas-fiscais/`
                );
                const nfResponse = await listAll(nfREF);
                await Promise.all(
                  nfResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    notasFiscais.push({ nome: itemRef.name, url: url });
                  })
                );

                const npRef = storageREF(
                  storage,
                  `duplicatas/${operacaoDoDia.data}/${operacaoId}/notas-promissorias/`
                );
                const npResponse = await listAll(npRef);
                await Promise.all(
                  npResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    notasPromissorias.push({ nome: itemRef.name, url: url });
                  })
                );

                const contratosRef = storageREF(
                  storage,
                  `duplicatas/${operacaoDoDia.data}/${operacaoId}/contratos/`
                );
                const contratosResponse = await listAll(contratosRef);
                await Promise.all(
                  contratosResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    contratos.push({ nome: itemRef.name, url: url });
                  })
                );

                const comprovantesRef = storageREF(
                  storage,
                  `duplicatas/${operacaoDoDia.data}/${operacaoId}/comprovantes-ted/`
                );
                const comprovantesResponse = await listAll(comprovantesRef);
                await Promise.all(
                  comprovantesResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    comprovantesTED.push({ nome: itemRef.name, url: url });
                  })
                );

                const canhotosREF = storageREF(
                  storage,
                  `duplicatas/${operacaoDoDia.data}/${operacaoId}/canhotos/`
                );
                const canhotosResponse = await listAll(canhotosREF);
                await Promise.all(
                  canhotosResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    canhotos.push({ nome: itemRef.name, url: url });
                  })
                );

                const informesREF = storageREF(
                  storage,
                  `duplicatas/${operacaoDoDia.data}/${operacaoId}/informes-de-transporte/`
                );
                const informesResponse = await listAll(informesREF);
                await Promise.all(
                  informesResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    informes.push({ nome: itemRef.name, url: url });
                  })
                );

                setOperacaoEncontrada({
                  ...operacaoDoDia,
                  tipo: "duplicatas",
                  notasFiscais: notasFiscais,
                  notasPromissorias: notasPromissorias,
                  contratos: contratos,
                  comprovantes: comprovantesTED,
                  canhotos: canhotos,
                  informes: informes,
                });
                setOperacaoId(operacaoId); // Salvar o ID da operação
                setLoading(false);
                return; // Encerra a função após encontrar a operação
              }
            }
          }
        }
      }
    }

    // Pesquisa em "cheque"
    const chequeSnapshot = await get(child(operacoesRef, "cheque"));
    if (chequeSnapshot.exists()) {
      const cheques = chequeSnapshot.val();
      const operacoes = Object.values(cheques);

      for (const operacao of operacoes) {
        const meses = Object.values(operacao);

        for (const mes of meses) {
          const dias = Object.values(mes);

          for (const dia of dias) {
            const operacoesDoDia = Object.entries(dia);

            for (const [operacaoId, operacaoDoDia] of operacoesDoDia) {
              if (operacaoDoDia.numero_da_operacao === numero) {
                // Exibir os dados da operação em uma tabela do Mui
                console.log('Operação encontrada em "cheque":', operacaoDoDia);
                const copiaCheques = [];
                const notasPromissorias = [];
                const contratos = [];
                const comprovantesTED = [];
                const storage = getStorage();

                const copiaChequeREF = storageREF(
                  storage,
                  `cheques/${operacaoDoDia.data}/${operacaoId}/copia-cheque/`
                );
                const cCResponse = await listAll(copiaChequeREF);
                await Promise.all(
                  cCResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    copiaCheques.push({ nome: itemRef.name, url: url });
                  })
                );

                const npRef = storageREF(
                  storage,
                  `cheques/${operacaoDoDia.data}/${operacaoId}/notas-promissorias/`
                );
                const npResponse = await listAll(npRef);
                await Promise.all(
                  npResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    notasPromissorias.push({ nome: itemRef.name, url: url });
                  })
                );

                const contratosRef = storageREF(
                  storage,
                  `cheques/${operacaoDoDia.data}/${operacaoId}/contratos/`
                );
                const contratosResponse = await listAll(contratosRef);
                await Promise.all(
                  contratosResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    contratos.push({ nome: itemRef.name, url: url });
                  })
                );

                const comprovantesRef = storageREF(
                  storage,
                  `cheques/${operacaoDoDia.data}/${operacaoId}/comprovantes-ted/`
                );
                const comprovantesResponse = await listAll(comprovantesRef);
                await Promise.all(
                  comprovantesResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    comprovantesTED.push({ nome: itemRef.name, url: url });
                  })
                );

                setOperacaoEncontrada({
                  ...operacaoDoDia,
                  tipo: "cheque",
                  copiasCheques: copiaCheques,
                  notasPromissorias: notasPromissorias,
                  contratos: contratos,
                  comprovantes: comprovantesTED,
                });
                setOperacaoId(operacaoId); // Salvar o ID da operação
                setLoading(false);

                return;
              }
            }
          }
        }
      }
    }
    const contratoSnapshot = await get(child(operacoesRef, "contrato"));
    if (contratoSnapshot.exists()) {
      const contratos = contratoSnapshot.val();
      const operacoes = Object.values(contratos);

      for (const operacao of operacoes) {
        const meses = Object.values(operacao);

        for (const mes of meses) {
          const dias = Object.values(mes);

          for (const dia of dias) {
            const operacoesDoDia = Object.entries(dia);

            for (const [operacaoId, operacaoDoDia] of operacoesDoDia) {
              if (operacaoDoDia.numero_da_operacao === numero) {
                // Exibir os dados da operação em uma tabela do Mui
                console.log(
                  'Operação encontrada em "Contrato":',
                  operacaoDoDia
                );
                /// Salvar o ID da operação

                const copiaContrato = [];
                const notasPromissorias = [];
                const contratos = [];
                const comprovantesTED = [];
                const storage = getStorage();

                const copiaChequeREF = storageREF(
                  storage,
                  `contratos/${operacaoDoDia.data}/${operacaoId}/copia-contrato/`
                );
                const cCResponse = await listAll(copiaChequeREF);
                await Promise.all(
                  cCResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    copiaContrato.push({ nome: itemRef.name, url: url });
                  })
                );

                const npRef = storageREF(
                  storage,
                  `contratos/${operacaoDoDia.data}/${operacaoId}/notas-promissorias/`
                );
                const npResponse = await listAll(npRef);
                await Promise.all(
                  npResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    notasPromissorias.push({ nome: itemRef.name, url: url });
                  })
                );

                const contratosRef = storageREF(
                  storage,
                  `contratos/${operacaoDoDia.data}/${operacaoId}/contratos/`
                );
                const contratosResponse = await listAll(contratosRef);
                await Promise.all(
                  contratosResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    contratos.push({ nome: itemRef.name, url: url });
                  })
                );

                const comprovantesRef = storageREF(
                  storage,
                  `contratos/${operacaoDoDia.data}/${operacaoId}/comprovantes-ted/`
                );
                const comprovantesResponse = await listAll(comprovantesRef);
                await Promise.all(
                  comprovantesResponse.items.map(async (itemRef) => {
                    const url = await getDownloadURL(itemRef);
                    comprovantesTED.push({ nome: itemRef.name, url: url });
                  })
                );

                setOperacaoEncontrada({
                  ...operacaoDoDia,
                  tipo: "contrato",
                  copiaContrato: copiaContrato,
                  notasPromissorias: notasPromissorias,
                  contratos: contratos,
                  comprovantes: comprovantesTED,
                });
                setLoading(false);

                setOperacaoId(operacaoId);

                return; // Encerra a função após encontrar a operação
              }
            }
          }
        }
      }
    }
    // Caso a operação não seja encontrada, você pode exibir uma mensagem ou realizar outra ação
    setLoading(false);
    alert("Operação não encontrada");
  };

  const excluirContrato = async (id, nome, data, tipoOp) => {
    const storage = getStorage();

    let tOperacao = "";
    if (tipoOp === "cheque") {
      tOperacao = "cheques";
    } else if (tipoOp === "contrato") {
      tOperacao = "contratos";
    } else {
      tOperacao = "duplicatas";
    }
    try {
      const contratoRef = storageREF(
        storage,
        `${tOperacao}/${data}/${id}/contratos/${nome}`
      );
      await deleteObject(contratoRef);
      setOperacaoEncontrada({
        ...operacaoEncontrada,
        contratos: operacaoEncontrada.contratos.filter(
          (contrato) => contrato.nome !== nome
        ),
      });

      console.log("Contrato excluido com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir contrato:", error);
    }
  };
  const excluirComprovante = async (id, nome, data, tipoOp) => {
    const storage = getStorage();

    let tOperacao = "";
    if (tipoOp === "cheque") {
      tOperacao = "cheques";
    } else if (tipoOp === "contrato") {
      tOperacao = "contratos";
    } else {
      tOperacao = "duplicatas";
    }
    const comprovanteRef = storageREF(
      storage,
      `${tOperacao}/${data}/${id}/comprovantes-ted/${nome}`
    );

    try {
      await deleteObject(comprovanteRef);
      setOperacaoEncontrada({
        ...operacaoEncontrada,
        comprovantes: operacaoEncontrada.comprovantes.filter(
          (comprovante) => comprovante.nome !== nome
        ),
      });

      console.log("Comprovante excluido com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir comprovante:", error);
    }
  };

  const excluirNotaPromissoria = async (id, nome, data, tipoOp) => {
    const storage = getStorage();

    let tOperacao = "";
    if (tipoOp === "cheque") {
      tOperacao = "cheques";
    } else if (tipoOp === "contrato") {
      tOperacao = "contratos";
    } else {
      tOperacao = "duplicatas";
    }
    try {
      const notaPromiRef = storageREF(
        storage,
        `${tOperacao}/${data}/${id}/notas-promissorias/${nome}`
      );
      await deleteObject(notaPromiRef);
      setOperacaoEncontrada({
        ...operacaoEncontrada,
        notasPromissorias: operacaoEncontrada.notasPromissorias.filter(
          (nota) => nota.nome !== nome
        ),
      });

      console.log("Nota Promissória excluid com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir Nota Promissória:", error);
    }
  };

  const excluirCopia = async (id, nome, data) => {
    const storage = getStorage();

    try {
      const copiaRef = storageREF(
        storage,
        `cheques/${data}/${id}/copia-cheque/${nome}`
      );
      await deleteObject(copiaRef);
      setOperacaoEncontrada({
        ...operacaoEncontrada,
        copiasCheques: operacaoEncontrada.copiasCheques.filter(
          (copia) => copia.nome !== nome
        ),
      });

      console.log("Cópia do Cheque excluida com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir Cópia do Cheque", error);
    }
  };

  const excluirCopiaContrato = async (id, nome, data) => {
    const storage = getStorage();

    try {
      const copiaRef = storageREF(
        storage,
        `contratos/${data}/${id}/copia-contrato/${nome}`
      );
      await deleteObject(copiaRef);

      setOperacaoEncontrada({
        ...operacaoEncontrada,
        copiaContrato: operacaoEncontrada.copiaContrato.filter(
          (copia) => copia.nome !== nome
        ),
      });
      console.log("Cópia excluida com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir Cópia", error);
    }
  };

  const excluirNF = async (id, nome, data, tipoOp) => {
    const storage = getStorage();
    let tOperacao = "";
    if (tipoOp === "cheque") {
      tOperacao = "cheques";
    } else if (tipoOp === "contrato") {
      tOperacao = "contratos";
    } else {
      tOperacao = "duplicatas";
    }
    try {
      const nfRef = storageREF(
        storage,
        `${tOperacao}/${data}/${id}/notas-fiscais/${nome}`
      );
      await deleteObject(nfRef);
      setOperacaoEncontrada({
        ...operacaoEncontrada,
        notasFiscais: operacaoEncontrada.notasFiscais.filter(
          (nota) => nota.nome !== nome
        ),
      });
      console.log("Nota Fiscal excluida com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir Nota Fiscal", error);
    }
  };
  const excluirCanhoto = async (id, nome, data, tipoOp) => {
    const storage = getStorage();
    let tOperacao = "";
    if (tipoOp === "cheque") {
      tOperacao = "cheques";
    } else if (tipoOp === "contrato") {
      tOperacao = "contratos";
    } else {
      tOperacao = "duplicatas";
    }
    try {
      const nfRef = storageREF(
        storage,
        `${tOperacao}/${data}/${id}/canhotos/${nome}`
      );
      await deleteObject(nfRef);
      setOperacaoEncontrada({
        ...operacaoEncontrada,
        canhotos: operacaoEncontrada.canhotos.filter(
          (nota) => nota.nome !== nome
        ),
      });
      console.log("Nota Fiscal excluida com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir Nota Fiscal", error);
    }
  };
  const excluirInforme = async (id, nome, data, tipoOp) => {
    const storage = getStorage();
    let tOperacao = "";
    if (tipoOp === "cheque") {
      tOperacao = "cheques";
    } else if (tipoOp === "contrato") {
      tOperacao = "contratos";
    } else {
      tOperacao = "duplicatas";
    }
    try {
      const nfRef = storageREF(
        storage,
        `${tOperacao}/${data}/${id}/informes-de-transporte/${nome}`
      );
      await deleteObject(nfRef);
      setOperacaoEncontrada({
        ...operacaoEncontrada,
        informes: operacaoEncontrada.informes.filter(
          (nota) => nota.nome !== nome
        ),
      });
    } catch (error) {}
  };

  const displayTable = () => {
    if (!operacaoEncontrada) return null;

    const { tipo, cedente, data, numero_da_operacao, arquivos_pendentes } =
      operacaoEncontrada;

    const excluirOperacao = () => {
      const database = getDatabase();
      const operacaoRef = ref(
        database,
        `operacoes/${tipo}/${data}/${operacaoId}`
      );
      remove(operacaoRef)
        .then(() => {
          alert("Operação excluída com sucesso!");
          console.log("Operação excluída com sucesso!");
          setTimeout(window.location.reload(), 500);
        })
        .catch((error) => {
          console.error("Erro ao excluir a operação:", error);
        });
    };

    const columns = ["Tipo", "Cedente", "Data", "Número", "Pendente"];

    return (
      <>
        {/*
            <EditarOperacao open={openEditarOperacao} handleClose={() => setOpenEditarOperacao(false)} cedente={cedente} data={data} numeroOp={numero_da_operacao} id={operacaoId} tipoOp={tipo} pendencias={pendencias} search={() => searchOperation()} />
             */}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
                <TableCell sx={{ display: "flex", justifyContent: "center" }}>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{tipo}</TableCell>
                <TableCell>{cedente}</TableCell>
                <TableCell>{data}</TableCell>
                <TableCell>{numero_da_operacao}</TableCell>

                {arquivos_pendentes ? (
                  <TableCell>Sim</TableCell>
                ) : (
                  <TableCell>Não</TableCell>
                )}
                <TableCell
                  sx={{
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/*<Button
                                    sx={{
                                        margin: "5px"
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenEditarOperacao(true)}>
                                    Editar Operação
                                </Button>*/}
                  <Button
                    sx={{
                      margin: "5px",
                    }}
                    variant="contained"
                    color="warning"
                    onClick={() => setOpenModalAdicionarArquivos(true)}
                  >
                    Adicionar Arquivos
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => excluirOperacao()}
                    sx={{
                      margin: "5px",
                    }}
                  >
                    Excluir Operação
                  </Button>
                  <AdicionarArquivos
                    open={openModalAdicionarArquivos}
                    handleClose={() => setOpenModalAdicionarArquivos(false)}
                    tipo={tipo}
                    data={data}
                    id={operacaoId}
                    arquivos_pendentes={arquivos_pendentes}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#fefefe" }}>
              <TableRow>
                <TableCell>Operação</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Link</TableCell>
                <TableCell sx={{ display: "flex", justifyContent: "center" }}>
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            {tipo === "duplicatas" ? (
              <>
                <TableBody>
                  {operacaoEncontrada &&
                    operacaoEncontrada.notasFiscais.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Nota Fiscal</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirNF(operacaoId, nota.nome, data, tipo)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {operacaoEncontrada &&
                    operacaoEncontrada.contratos.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Contratos</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirContrato(operacaoId, nota.nome, data, tipo)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  {operacaoEncontrada &&
                    operacaoEncontrada.notasPromissorias.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Nota Promissória</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirNotaPromissoria(
                                operacaoId,
                                nota.nome,
                                data,
                                tipo
                              )
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  {operacaoEncontrada &&
                    operacaoEncontrada.comprovantes.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Comprovante</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirComprovante(
                                operacaoId,
                                nota.nome,
                                data,
                                tipo
                              )
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  {operacaoEncontrada &&
                    operacaoEncontrada.canhotos.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Canhoto</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirCanhoto(operacaoId, nota.nome, data, tipo)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}

                  {operacaoEncontrada &&
                    operacaoEncontrada.informes.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Informe de Transporte</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirInforme(operacaoId, nota.nome, data, tipo)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </>
            ) : tipo === "cheque" ? (
              <>
                <TableBody>
                  {operacaoEncontrada &&
                    operacaoEncontrada.copiasCheques.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Cópias dos Cheques</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirCopia(operacaoId, nota.nome, data)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  {operacaoEncontrada &&
                    operacaoEncontrada.contratos.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Contratos</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirContrato(operacaoId, nota.nome, data, tipo)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>
                        </TableCell>{" "}
                      </TableRow>
                    ))}{" "}
                  {operacaoEncontrada &&
                    operacaoEncontrada.notasPromissorias.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Nota Promissória</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirNotaPromissoria(
                                operacaoId,
                                nota.nome,
                                data,
                                tipo
                              )
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}{" "}
                  {operacaoEncontrada &&
                    operacaoEncontrada.comprovantes.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Comprovante</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirComprovante(
                                operacaoId,
                                nota.nome,
                                data,
                                tipo
                              )
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </>
            ) : (
              <>
                <TableBody>
                  {operacaoEncontrada &&
                    operacaoEncontrada.copiaContrato.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Copia Contrato</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirCopiaContrato(operacaoId, nota.nome, data)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                  {operacaoEncontrada &&
                    operacaoEncontrada.contratos.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Contratos</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirContrato(operacaoId, nota.nome, data, tipo)
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}{" "}
                  {operacaoEncontrada &&
                    operacaoEncontrada.notasPromissorias.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Nota Promissória</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirNotaPromissoria(
                                operacaoId,
                                nota.nome,
                                data,
                                tipo
                              )
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}{" "}
                  {operacaoEncontrada &&
                    operacaoEncontrada.comprovantes.map((nota, index) => (
                      <TableRow key={index}>
                        <TableCell>{nota.nome}</TableCell>
                        <TableCell>Comprovante</TableCell>
                        <TableCell>
                          <a
                            href={nota.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Abrir
                          </a>
                        </TableCell>
                        <TableCell
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() =>
                              excluirComprovante(
                                operacaoId,
                                nota.nome,
                                data,
                                tipo
                              )
                            }
                          >
                            {" "}
                            <Delete color="white" /> Apagar arquivo
                          </Button>{" "}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <ResponsiveAppBar />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 6,
        }}
      >
        <TextField
          size="small"
          sx={{ margin: 1, width: 300 }}
          label="Número da Operação"
          value={numero}
          onChange={(e) => setNumero(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => searchOperation()}
        >
          Buscar
        </Button>
      </Box>
      <Box
        sx={{
          padding: 5,
        }}
      >
        {loading ? (
          <Box
            sx={{
              height: "30vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{ fontWeight: "700", marginRight: 3 }}
            >
              Procurando operação
            </Typography>
            <CircularProgress />
          </Box>
        ) : (
          displayTable()
        )}
        {/* operacaoId && <p>ID da operação: {operacaoId}</p> */}
      </Box>
    </Box>
  );
};

export default ListarOperacoes_REFACT;
