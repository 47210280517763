import { Box, Typography } from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../../Components/ResponsiveAppbar";
import RegistrationForm from "../../Components/RegistrationForm";

function Cadastros() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <ResponsiveAppBar />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Cadastrar Usuários
        </Typography>
        <RegistrationForm />
      </Box>
    </Box>
  );
}

export default Cadastros;
