import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  InputLabel,
  Snackbar,
} from "@mui/material";
import { getDatabase, ref as databaseREF, get, set } from "firebase/database";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useState } from "react";

const AdicionarArquivos = (props) => {
  const [arquivos_notas_fiscais, setArquivos_Notas_Fiscais] = useState([]);
  const [arquivos_nota_promissoria, setArquivos_Nota_Promissoria] = useState(
    []
  );
  const [arquivos_cheques, setArquivos_Cheques] = useState([]);
  const [arquivos_comprovantes, setArquivos_Comprovantes] = useState([]);
  const [arquivos_contrato, setArquivos_Contrato] = useState([]);
  const [arquivos_copia_contrato, setArquivos_Copia_Contrato] = useState([]);
  const [arquivos_canhotos, setArquivosCanhotos] = useState([]);
  const [arquivos_informes, setArquivos_Informes] = useState([]);
  const [loadingContrato, setLoadingContrato] = useState(false);
  const [loadingCopiaContrato, setLoadingCopiaContrato] = useState(false);
  const [loadingNotaPromissoria, setLoadingNotaPromissoria] = useState(false);
  const [loadingComprovante, setLoadingComprovante] = useState(false);
  const [loadingNotasFiscais, setLoadingNotasFiscais] = useState(false);
  const [loadingCopiaCheque, setLoadingCopiaCheque] = useState(false);
  const [loadingCanhoto, setLoadingCanhoto] = useState(false);
  const [loadingInformes, setLoadingInformes] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSnackbarClose = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  const notasPromissoriasSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Nota_Promissoria((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };
  const contratosSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Contrato((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };
  const copia_contratosSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Copia_Contrato((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const chequeSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Cheques((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const comprovantesSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Comprovantes((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const notasFiscaisSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Notas_Fiscais((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const canhotosSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivosCanhotos((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };
  const informesSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Informes((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const enviarArquivo = async (tipo_de_operacao, tipo_de_arquivo, date, id) => {
    const storage = getStorage();
    const database = getDatabase();

    console.log(`Tipo de arquivo enviado: ${tipo_de_arquivo}`);
    console.log(`Tipo de operação: ${tipo_de_operacao}`);
    if (tipo_de_operacao.toLowerCase() === "duplicatas") {
      const referenciaArquivosPendentes = databaseREF(
        database,
        `operacoes/duplicatas/${date}/${id}/arquivos_pendentes`
      );

      try {
        if (tipo_de_arquivo === "contratos") {
          setLoadingContrato(true);
          for (let i = 0; i < arquivos_contrato.length; i++) {
            const storageRef = ref(
              storage,
              `duplicatas/${date}/${id}/${tipo_de_arquivo}/${arquivos_contrato[i].name}`
            );
            const metadata = {
              contentType: arquivos_contrato[i].type,
            };
            await uploadBytes(storageRef, arquivos_contrato[i], metadata);
          }

          console.log(`Arquivos pendentes: `);
          console.log(props.arquivos_pendentes);
          if (props.arquivos_pendentes.includes("Contrato")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Contrato");
                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Notas Promissórias" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Notas Promissórias" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        }
        if (tipo_de_arquivo === "comprovantes-ted") {
          setLoadingComprovante(true);
          for (let i = 0; i < arquivos_comprovantes.length; i++) {
            const storageRef = ref(
              storage,
              `duplicatas/${date}/${id}/${tipo_de_arquivo}/${arquivos_comprovantes[i].name}`
            );
            const metadata = {
              contentType: arquivos_comprovantes[i].type,
            };
            await uploadBytes(storageRef, arquivos_comprovantes[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Comprovantes")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Comprovantes");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      setSuccessMessage("Comprovante excluído com sucesso");
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  setErrorMessage(
                    'O item "Comprovante" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                setErrorMessage(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        }
        if (tipo_de_arquivo === "notas-promissorias") {
          setLoadingNotaPromissoria(true);
          for (let i = 0; i < arquivos_nota_promissoria.length; i++) {
            const storageRef = ref(
              storage,
              `duplicatas/${date}/${id}/${tipo_de_arquivo}/${arquivos_nota_promissoria[i].name}`
            );
            const metadata = {
              contentType: arquivos_nota_promissoria[i].type,
            };
            await uploadBytes(
              storageRef,
              arquivos_nota_promissoria[i],
              metadata
            );
          }
          if (props.arquivos_pendentes.includes("Notas Promissórias")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex =
                  arquivosPendentes.indexOf("Notas Promissórias");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {})
                    .catch((error) => {});
                } else {
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        }
        if (tipo_de_arquivo === "notas-fiscais") {
          setLoadingNotasFiscais(true);
          for (let i = 0; i < arquivos_notas_fiscais.length; i++) {
            const storageRef = ref(
              storage,
              `duplicatas/${date}/${id}/${tipo_de_arquivo}/${arquivos_notas_fiscais[i].name}`
            );
            const metadata = {
              contentType: arquivos_notas_fiscais[i].type,
            };
            await uploadBytes(storageRef, arquivos_notas_fiscais[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Notas Fiscais")) {
            console.log(`Arquivos pendentes: ${props.arquivosPendentes}`);
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Notas Fiscais");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      setSuccessMessage("Notas Fiscais resolvido com sucesso");
                    })
                    .catch((error) => {});
                } else {
                  setErrorMessage(
                    'O item "Notas Fiscais" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                setErrorMessage(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        } else if (tipo_de_arquivo === "canhotos") {
          setLoadingCanhoto(true);
          for (let i = 0; i < arquivos_canhotos.length; i++) {
            const storageRef = ref(
              storage,
              `duplicatas/${date}/${id}/${tipo_de_arquivo}/${arquivos_canhotos[i].name}`
            );
            const metadata = {
              contentType: arquivos_canhotos[i].type,
            };
            await uploadBytes(storageRef, arquivos_canhotos[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Canhotos")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Canhotos");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {})
                    .catch((error) => {});
                } else {
                }
              })
              .catch((error) => {});
          }
        } else if (tipo_de_arquivo === "informes-de-transporte") {
          setLoadingInformes(true);
          for (let i = 0; i < arquivos_informes.length; i++) {
            const storageRef = ref(
              storage,
              `duplicatas/${date}/${id}/${tipo_de_arquivo}/${arquivos_informes[i].name}`
            );
            const metadata = {
              contentType: arquivos_informes[i].type,
            };
            await uploadBytes(storageRef, arquivos_informes[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Informes de Transporte")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf(
                  "Informes de Transporte"
                );

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {})
                    .catch((error) => {});
                } else {
                }
              })
              .catch((error) => {});
          }
        }

        setSuccessMessage("Arquivo enviado com sucesso!");
      } catch (error) {
      } finally {
        setLoadingContrato(false);
        setLoadingComprovante(false);
        setLoadingNotasFiscais(false);
        setLoadingNotaPromissoria(false);
        setLoadingInformes(false);
        setLoadingCanhoto(false);
      }
    } else if (tipo_de_operacao.toLowerCase() === "cheques") {
      const referenciaArquivosPendentes = databaseREF(
        database,
        `operacoes/cheque/${date}/${id}/arquivos_pendentes`
      );
      try {
        if (tipo_de_arquivo === "contratos") {
          setLoadingContrato(true);
          for (let i = 0; i < arquivos_contrato.length; i++) {
            const storageRef = ref(
              storage,
              `cheques/${date}/${id}/${tipo_de_arquivo}/${arquivos_contrato[i].name}`
            );
            const metadata = {
              contentType: arquivos_contrato[i].type,
            };
            await uploadBytes(storageRef, arquivos_contrato[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Contrato")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Contrato");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Notas Promissórias" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Notas Promissórias" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        } else if (tipo_de_arquivo === "comprovantes-ted") {
          setLoadingComprovante(true);
          for (let i = 0; i < arquivos_comprovantes.length; i++) {
            const storageRef = ref(
              storage,
              `cheques/${date}/${id}/${tipo_de_arquivo}/${arquivos_comprovantes[i].name}`
            );
            const metadata = {
              contentType: arquivos_comprovantes[i].type,
            };
            await uploadBytes(storageRef, arquivos_comprovantes[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Comprovantes")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Comprovantes");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Notas Promissórias" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Notas Promissórias" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        } else if (tipo_de_arquivo === "notas-promissorias") {
          setLoadingNotaPromissoria(true);
          for (let i = 0; i < arquivos_nota_promissoria.length; i++) {
            const storageRef = ref(
              storage,
              `cheques/${date}/${id}/${tipo_de_arquivo}/${arquivos_nota_promissoria[i].name}`
            );
            const metadata = {
              contentType: arquivos_nota_promissoria[i].type,
            };
            await uploadBytes(
              storageRef,
              arquivos_nota_promissoria[i],
              metadata
            );
          }
          if (props.arquivos_pendentes.includes("Notas Promissórias")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex =
                  arquivosPendentes.indexOf("Notas Promissórias");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Notas Promissórias" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Notas Promissórias" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        } else if (tipo_de_arquivo === "copia-cheque") {
          setLoadingCopiaCheque(true);
          for (let i = 0; i < arquivos_cheques.length; i++) {
            const storageRef = ref(
              storage,
              `cheques/${date}/${id}/${tipo_de_arquivo}/${arquivos_cheques[i].name}`
            );
            const metadata = {
              contentType: arquivos_cheques[i].type,
            };
            await uploadBytes(storageRef, arquivos_cheques[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Cópia Cheque")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Cópia Cheque");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Cópia Cheque" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Cópia Cheque" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        }
        setSuccessMessage("Arquivo enviado com sucesso!");
      } catch (error) {
        console.log(error);
        setErrorMessage("Erro ao enviar arquivo. Tente novamente!");
      } finally {
        setLoadingContrato(false);
        setLoadingComprovante(false);
        setLoadingNotaPromissoria(false);
        setLoadingCopiaCheque(false);
      }
    } else if (tipo_de_operacao.toLowerCase() === "contratos") {
      const referenciaArquivosPendentes = databaseREF(
        database,
        `operacoes/contrato/${date}/${id}/arquivos_pendentes`
      );

      try {
        if (tipo_de_arquivo === "contratos") {
          setLoadingContrato(true);
          for (let i = 0; i < arquivos_contrato.length; i++) {
            const storageRef = ref(
              storage,
              `contratos/${date}/${id}/${tipo_de_arquivo}/${arquivos_contrato[i].name}`
            );
            const metadata = {
              contentType: arquivos_contrato[i].type,
            };
            await uploadBytes(storageRef, arquivos_contrato[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Contrato")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Contrato");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Cópia Cheque" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Cópia Cheque" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        } else if (tipo_de_arquivo === "comprovantes-ted") {
          setLoadingComprovante(true);
          for (let i = 0; i < arquivos_comprovantes.length; i++) {
            const storageRef = ref(
              storage,
              `contratos/${date}/${id}/${tipo_de_arquivo}/${arquivos_comprovantes[i].name}`
            );
            const metadata = {
              contentType: arquivos_comprovantes[i].type,
            };
            await uploadBytes(storageRef, arquivos_comprovantes[i], metadata);
          }
          if (props.arquivos_pendentes.includes("Comprovantes")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Comprovantes");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Notas Promissórias" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Notas Promissórias" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        } else if (tipo_de_arquivo === "notas-promissorias") {
          setLoadingNotaPromissoria(true);
          for (let i = 0; i < arquivos_nota_promissoria.length; i++) {
            const storageRef = ref(
              storage,
              `contratos/${date}/${id}/${tipo_de_arquivo}/${arquivos_nota_promissoria[i].name}`
            );
            const metadata = {
              contentType: arquivos_nota_promissoria[i].type,
            };
            await uploadBytes(
              storageRef,
              arquivos_nota_promissoria[i],
              metadata
            );
          }
          if (props.arquivos_pendentes.includes("Notas Promissórias")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex =
                  arquivosPendentes.indexOf("Notas Promissórias");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Notas Promissórias" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Notas Promissórias" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        } else if (tipo_de_arquivo === "copia-contrato") {
          setLoadingCopiaContrato(true);
          for (let i = 0; i < arquivos_copia_contrato.length; i++) {
            const storageRef = ref(
              storage,
              `contratos/${date}/${id}/${tipo_de_arquivo}/${arquivos_copia_contrato[i].name}`
            );
            const metadata = {
              contentType: arquivos_copia_contrato[i].type,
            };
            await uploadBytes(storageRef, arquivos_copia_contrato[i], metadata);
          }

          if (props.arquivos_pendentes.includes("Cópia Contrato")) {
            get(referenciaArquivosPendentes)
              .then((snapshot) => {
                const arquivosPendentes = snapshot.val();
                const itemIndex = arquivosPendentes.indexOf("Cópia Contrato");

                if (itemIndex > -1) {
                  arquivosPendentes.splice(itemIndex, 1);
                  set(referenciaArquivosPendentes, arquivosPendentes)
                    .then(() => {
                      console.log(
                        'Item "Notas Promissórias" excluído das pendências com sucesso.'
                      );
                    })
                    .catch((error) => {
                      console.error(
                        'Erro ao excluir o item "Comprovantes":',
                        error
                      );
                    });
                } else {
                  console.log(
                    'O item "Notas Promissórias" não foi encontrado na lista.'
                  );
                }
              })
              .catch((error) => {
                console.error(
                  "Erro ao obter a lista de arquivos pendentes:",
                  error
                );
              });
          }
        }
        setSuccessMessage("Arquivo enviado com sucesso!");
      } catch (error) {
        console.log(error);
        setErrorMessage("Erro ao enviar arquivo. Tente novamente!");
      } finally {
        setLoadingContrato(false);
        setLoadingComprovante(false);
        setLoadingNotaPromissoria(false);
        setLoadingCopiaContrato(false);
      }
    }
  };
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Adicionar Novos Arquivos a Operação</DialogTitle>

        <DialogContent>
          <Snackbar
            open={!!successMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              variant="filled"
            >
              {successMessage}
            </Alert>
          </Snackbar>

          {/* Renderiza o aviso de erro */}
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              variant="filled"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          <div>
            {!loadingContrato ? (
              <>
                <InputLabel htmlFor="contrat-aditivo">
                  Contrato Aditivo
                </InputLabel>
                <Input
                  type="file"
                  id="contrato-aditivo"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  onChange={contratosSelect}
                  inputProps={{ multiple: true }}
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onClick={() =>
                    enviarArquivo(props.tipo, "contratos", props.data, props.id)
                  }
                >
                  Enviar
                </Button>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>

          <div>
            {!loadingCopiaContrato ? (
              <>
                {props.tipo === "Contratos" ? (
                  <>
                    <InputLabel htmlFor="copia-contrato">
                      Cópia Contrato
                    </InputLabel>
                    <Input
                      type="file"
                      id="copia-contrato"
                      sx={{ marginBottom: 2 }}
                      fullWidth
                      onChange={copia_contratosSelect}
                      inputProps={{ multiple: true }}
                      size="small"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      onClick={() =>
                        enviarArquivo(
                          props.tipo,
                          "copia-contrato",
                          props.data,
                          props.id
                        )
                      }
                    >
                      Enviar
                    </Button>
                  </>
                ) : (
                  <> </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>

          <div>
            {!loadingNotaPromissoria ? (
              <>
                <InputLabel htmlFor="nota-promissoria">
                  Nota Promissória
                </InputLabel>
                <Input
                  type="file"
                  id="nota-promissoria"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  onChange={notasPromissoriasSelect}
                  inputProps={{ multiple: true }}
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onClick={() =>
                    enviarArquivo(
                      props.tipo,
                      "notas-promissorias",
                      props.data,
                      props.id
                    )
                  }
                >
                  Enviar
                </Button>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>

          <div>
            {!loadingComprovante ? (
              <>
                <InputLabel htmlFor="comprovantes-ted">
                  Comprovantes TED
                </InputLabel>
                <Input
                  type="file"
                  id="comprovantes-ted"
                  sx={{ marginBottom: 2 }}
                  fullWidth
                  onChange={comprovantesSelect}
                  inputProps={{ multiple: true }}
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginBottom: 3 }}
                  onClick={() =>
                    enviarArquivo(
                      props.tipo,
                      "comprovantes-ted",
                      props.data,
                      props.id
                    )
                  }
                >
                  Enviar
                </Button>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>

          <div>
            {!loadingCopiaCheque ? (
              <>
                {props.tipo === "Cheques" ? (
                  <>
                    <InputLabel htmlFor="cheque">Cópia dos Cheques</InputLabel>
                    <Input
                      type="file"
                      id="cheque"
                      sx={{ marginBottom: 2 }}
                      fullWidth
                      onChange={chequeSelect}
                      inputProps={{ multiple: true }}
                      size="small"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      onClick={() =>
                        enviarArquivo(
                          props.tipo,
                          "copia-cheque",
                          props.data,
                          props.id
                        )
                      }
                    >
                      Enviar
                    </Button>
                  </>
                ) : (
                  <> </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>

          <div>
            {!loadingNotasFiscais ? (
              <>
                {props.tipo === "Duplicatas" || props.tipo === "duplicatas" ? (
                  <>
                    {" "}
                    <InputLabel htmlFor="notas-fiscais">
                      Notas Fiscais
                    </InputLabel>
                    <Input
                      type="file"
                      id="notas-fiscais"
                      sx={{ marginBottom: 2 }}
                      fullWidth
                      onChange={notasFiscaisSelect}
                      inputProps={{ multiple: true }}
                      size="small"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      onClick={() =>
                        enviarArquivo(
                          props.tipo,
                          "notas-fiscais",
                          props.data,
                          props.id
                        )
                      }
                    >
                      Enviar
                    </Button>
                  </>
                ) : (
                  <> </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>

          <div>
            {!loadingCanhoto ? (
              <>
                {props.tipo === "Duplicatas" || props.tipo === "duplicatas" ? (
                  <>
                    {" "}
                    <InputLabel htmlFor="canhotos">Canhotos</InputLabel>
                    <Input
                      type="file"
                      id="canhotos"
                      sx={{ marginBottom: 2 }}
                      fullWidth
                      onChange={canhotosSelect}
                      inputProps={{ multiple: true }}
                      size="small"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      onClick={() =>
                        enviarArquivo(
                          props.tipo,
                          "canhotos",
                          props.data,
                          props.id
                        )
                      }
                    >
                      Enviar
                    </Button>{" "}
                  </>
                ) : (
                  <> </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>

          <div>
            {!loadingInformes ? (
              <>
                {props.tipo === "Duplicatas" || props.tipo === "duplicatas" ? (
                  <>
                    {" "}
                    <InputLabel htmlFor="informes">
                      Informes de Transporte
                    </InputLabel>
                    <Input
                      type="file"
                      id="informes"
                      sx={{ marginBottom: 2 }}
                      fullWidth
                      onChange={informesSelect}
                      inputProps={{ multiple: true }}
                      size="small"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginBottom: 3 }}
                      onClick={() =>
                        enviarArquivo(
                          props.tipo,
                          "informes-de-transporte",
                          props.data,
                          props.id
                        )
                      }
                    >
                      Enviar
                    </Button>{" "}
                  </>
                ) : (
                  <> </>
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              props.handleClose();
            }}
          >
            Finalizar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdicionarArquivos;
