import React, { useState } from "react";
import ResponsiveAppBar from "../../Components/ResponsiveAppbar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { child, getDatabase, off, onValue, ref } from "firebase/database";
import "chart.js/auto";
import { Add } from "@mui/icons-material";
import AdicionarArquivos from "../../Components/AdicionarArquivos";
import FinalizarOperacao from "../../Components/FinalizarOperacao";

function Home() {
  const [operacoesPendentes, setOperacoesPendentes] = useState([]);
  const [operacaoSelecionada, setOperacaoSelecionada] = useState();
  const [openAdicionarArquivos, setOpenAdicionarArquivos] = useState(false);

  useEffect(() => {
    const database = getDatabase();
    const operacoesRef = ref(database, "operacoes/");

    const fetchData = async () => {
      setOperacoesPendentes([]);
      const pendentes = {};
      onValue(child(operacoesRef, "duplicatas"), (snapshot) => {
        if (snapshot.exists()) {
          const duplicatas = snapshot.val();
          const operacoes = Object.values(duplicatas);
          for (const operacao of operacoes) {
            const meses = Object.values(operacao);
            for (const mes of meses) {
              const dias = Object.values(mes);
              for (const dia of dias) {
                const operacoesDoDia = Object.entries(dia);
                for (const [operacaoId, operacaoDoDia] of operacoesDoDia) {
                  if (operacaoDoDia.arquivos_pendentes) {
                    const data = new Date(operacaoDoDia.data);
                    const ano = data.getFullYear();
                    const mes = (data.getMonth() + 1)
                      .toString()
                      .padStart(2, "0"); // Adiciona zero à esquerda se o mês tiver apenas uma casa
                    const chaveAno = ano.toString();
                    const chaveMes = mes.toString();
                    if (!pendentes[chaveAno]) {
                      pendentes[chaveAno] = {};
                    }

                    if (!pendentes[chaveAno][String(chaveMes)]) {
                      pendentes[chaveAno][String(chaveMes)] = [];
                    }
                    pendentes[chaveAno][chaveMes].push({
                      ...operacaoDoDia,
                      tipo: "Duplicatas",
                      id: operacaoId,
                    });
                  }
                }
              }
            }
          }
        }
      });

      onValue(child(operacoesRef, "cheque"), (snapshot) => {
        if (snapshot.exists()) {
          const cheques = snapshot.val();
          const operacoes = Object.values(cheques);
          for (const operacao of operacoes) {
            const meses = Object.values(operacao);
            for (const mes of meses) {
              const dias = Object.values(mes);
              for (const dia of dias) {
                const operacoesDoDia = Object.entries(dia);
                for (const [operacaoId, operacaoDoDia] of operacoesDoDia) {
                  if (operacaoDoDia.arquivos_pendentes) {
                    const data = new Date(operacaoDoDia.data);
                    const ano = data.getFullYear();
                    const mes = (data.getMonth() + 1)
                      .toString()
                      .padStart(2, "0"); // Adiciona zero à esquerda se o mês tiver apenas uma casa
                    const chaveAno = ano.toString();
                    const chaveMes = mes.toString();
                    if (!pendentes[chaveAno]) {
                      pendentes[chaveAno] = {};
                    }

                    if (!pendentes[chaveAno][String(chaveMes)]) {
                      pendentes[chaveAno][String(chaveMes)] = [];
                    }
                    pendentes[chaveAno][chaveMes].push({
                      ...operacaoDoDia,
                      tipo: "Cheques",
                      id: operacaoId,
                    });
                  }
                }
              }
            }
          }
        }
      });

      onValue(child(operacoesRef, "contrato"), (snapshot) => {
        if (snapshot.exists()) {
          const contratos = snapshot.val();
          const operacoes = Object.values(contratos);
          for (const operacao of operacoes) {
            const meses = Object.values(operacao);
            for (const mes of meses) {
              const dias = Object.values(mes);
              for (const dia of dias) {
                const operacoesDoDia = Object.entries(dia);
                for (const [operacaoId, operacaoDoDia] of operacoesDoDia) {
                  if (operacaoDoDia.arquivos_pendentes) {
                    const data = new Date(operacaoDoDia.data);
                    const ano = data.getFullYear();
                    const mes = (data.getMonth() + 1)
                      .toString()
                      .padStart(2, "0"); // Adiciona zero à esquerda se o mês tiver apenas uma casa
                    const chaveAno = ano.toString();
                    const chaveMes = mes.toString();
                    if (!pendentes[chaveAno]) {
                      pendentes[chaveAno] = {};
                    }

                    if (!pendentes[chaveAno][String(chaveMes)]) {
                      pendentes[chaveAno][String(chaveMes)] = [];
                    }

                    pendentes[chaveAno][chaveMes].push({
                      ...operacaoDoDia,
                      tipo: "Contratos",
                      id: operacaoId,
                    });
                  }
                }
              }
            }
          }
        }
      });
      setOperacoesPendentes(pendentes);
    };

    fetchData();

    // Retornar uma função de limpeza para remover os listeners quando o componente for desmontado
    return () => {
      // Remova os listeners aqui usando a função off() do Firebase Realtime Database
      // Exemplo: off(child(operacoesRef, 'duplicatas'))
      off(child(operacoesRef, "duplicatas"));
      off(child(operacoesRef, "cheque"));
      off(child(operacoesRef, "contrato"));
    };
  }, []);

  const atualizarArquivosPendentes = (novaLista) => {
    setOperacaoSelecionada({
      ...operacaoSelecionada,
      arquivos_pendentes: [...novaLista],
    });
  };

  const handleCancel = () => {
    console.log("Operação cancelada.");
  };

  return (
    <div
      style={{
        backgroundColor: "#dcdcdd",
        height: "auto",
      }}
    >
      <ResponsiveAppBar />

      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          height: "auto",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/*
                         <Box sx={{ width: '90%', height: '175px', display: 'flex', marginBottom: 7 }}>
                            <Box sx={{ width: "32%", margin: 1, height: "100%", backgroundColor: "white", display: "flex", borderRadius: 5, padding: 2, justifyContent: "space-around" }}>

                                <Box sx={{ height: "100%" }}>
                                    <Typography variant="h6" color="primary" sx={{ fontWeight: "600" }}>Duplicatas</Typography>
                                    <Box sx={{ height: "75%", display: "flex", alignItems: "center" }}>
                                        <Typography variant="h2" sx={{ fontWeight: "700", display: "flex", alignItems: 'center' }}><Typography variant='h4' sx={{ fontWeight: "300", marginRight: 1 }} color={"primary"}>+</Typography>{duplicatasList.length}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ height: "100%", width: "40%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <StickyNote2 sx={{ width: "70px", height: "70px" }} color="primary" />
                                </Box>

                            </Box>
                            <Box sx={{ width: "32%", margin: 1, height: "100%", backgroundColor: "white", borderRadius: 5, padding: 2 }}>
                                <Typography variant="p" color="primary" sx={{ fontWeight: "600" }}>Cheques</Typography>
                            </Box>
                            <Box sx={{ width: "32%", margin: 1, height: "100%", backgroundColor: "white", borderRadius: 5, padding: 2 }}>
                                <Typography variant="p" color="primary" sx={{ fontWeight: "600" }}>Contratos</Typography>
                            </Box>

                        </Box>
                    */}

        <Box
          sx={{
            width: "90%",
            height: "auto",
            display: "flex",
            backgroundColor: "white",
            borderRadius: 7,
            marginTop: 3,
          }}
        >
          <Box sx={{ width: "100%", padding: 4, textAlign: "left" }}>
            <Typography
              variant="h5"
              color="primary"
              sx={{ fontWeight: "800", textAlign: "center" }}
            >
              Operações com arquivos pendentes
            </Typography>

            <TableContainer
              component={Paper}
              sx={{ maxWidth: "100%", marginTop: 2 }}
            >
              <Table>
                <TableBody>
                  {Object.entries(operacoesPendentes).map(
                    ([chaveAno, operacoesPorAno]) => (
                      <Accordion
                        key={chaveAno}
                        TransitionProps={{ unmountOnExit: true }}
                      >
                        <AccordionSummary sx={{ backgroundColor: "#dcdcdd" }}>
                          <Typography sx={{ display: "flex" }}>
                            Clique para ver as operações do ano{" "}
                            <Typography
                              color="primary"
                              sx={{ marginLeft: 1, fontWeight: "800" }}
                            >
                              {chaveAno}
                            </Typography>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Table sx={{ width: "100%" }}>
                            <TableBody sx={{ width: "100%" }}>
                              {Object.entries(operacoesPorAno).map(
                                ([chaveMes, operacoesPorMes]) => (
                                  <Accordion
                                    key={`${chaveAno}-${chaveMes}`}
                                    TransitionProps={{ unmountOnExit: true }}
                                  >
                                    <AccordionSummary
                                      sx={{ backgroundColor: "#dcdcdd" }}
                                    >
                                      <Typography sx={{ display: "flex" }}>
                                        Clique para ver as operações do mês{" "}
                                        <Typography
                                          color="primary"
                                          sx={{
                                            marginLeft: 1,
                                            fontWeight: "800",
                                          }}
                                        >
                                          {chaveMes}
                                        </Typography>
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table sx={{ width: "100%" }}>
                                        <TableBody sx={{ width: "100%" }}>
                                          {operacoesPorMes.map((operacao) => (
                                            <TableRow
                                              key={operacao.id}
                                              sx={{ width: "100%" }}
                                            >
                                              <TableCell
                                                sx={{ textAlign: "center" }}
                                              >
                                                {operacao.tipo}
                                              </TableCell>
                                              <TableCell
                                                sx={{ textAlign: "center" }}
                                              >
                                                {operacao.numero_da_operacao}
                                              </TableCell>
                                              <TableCell
                                                sx={{ textAlign: "center" }}
                                              >
                                                {operacao.arquivos_pendentes.map(
                                                  (arquivo, index) => (
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent:
                                                          "space-around",
                                                      }}
                                                      key={index}
                                                    >
                                                      {arquivo}
                                                    </p>
                                                  )
                                                )}
                                              </TableCell>
                                              <TableCell
                                                sx={{ textAlign: "center" }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Button
                                                    color="primary"
                                                    variant="contained"
                                                    sx={{ margin: 1 }}
                                                    onClick={() => {
                                                      setOperacaoSelecionada(
                                                        operacao
                                                      );
                                                      console.log(operacao);
                                                      setOpenAdicionarArquivos(
                                                        !openAdicionarArquivos
                                                      );
                                                    }}
                                                  >
                                                    <Add />
                                                  </Button>
                                                  {operacao.arquivos_pendentes.every(
                                                    (item) =>
                                                      item === "Comprovantes"
                                                  ) && (
                                                    <FinalizarOperacao
                                                      onCancel={handleCancel}
                                                      operacao={operacao}
                                                    />
                                                  )}
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <AdicionarArquivos
              open={openAdicionarArquivos}
              handleClose={() => {
                setOpenAdicionarArquivos(false);
                window.location.reload();
              }}
              tipo={operacaoSelecionada && operacaoSelecionada.tipo}
              data={operacaoSelecionada && operacaoSelecionada.data}
              id={operacaoSelecionada && operacaoSelecionada.id}
              arquivos_pendentes={
                operacaoSelecionada && operacaoSelecionada.arquivos_pendentes
              }
              atualizarArquivosPendentes={atualizarArquivosPendentes}
            />
          </Box>
        </Box>
        {/*

                                <Box sx={{ width: '90%', height: "320px", paddingTop: 6, paddingBottom: 6, backgroundColor: "white", margin: 5, borderRadius: 5, display: "flex", justifyContent: "space-around" }}>
                                    <Bar data={data} options={options} />
                                    <Box sx={{ backgroundColor: "green" }}>

                                    </Box>
                                </Box>
                    */}
      </Box>
    </div>
  );
}

export default Home;
