import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import OperacoesContainer from "../../../Components/OperacoesContainer";
import ResponsiveAppBar from "../../../Components/ResponsiveAppbar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Input,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { getDatabase, ref as dbRef, set, get } from "firebase/database";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";
import { Select, MenuItem } from "@mui/material"; // ou "@material-ui/core"

function CadastrarOperacoes() {
  const [data, setData] = useState("");
  const [numeroOperacao, setNumeroOperacao] = useState("");
  const [cedente, setCedente] = useState("");
  const [pendencias, setPendencias] = useState([]);
  const [cedentes, setCedentes] = useState([]);
  const [arquivos_cheques, setArquivos_Cheques] = useState([]);
  const [arquivos_contrato, setArquivos_Contrato] = useState([]);
  const [arquivos_copia_contrato, setArquivos_Copia_Contrato] = useState([]);

  const [arquivos_nota_promissoria, setArquivos_Nota_Promissoria] = useState(
    []
  );
  const [arquivos_comprovantes, setArquivos_Comprovantes] = useState([]);
  const [arquivos_notas_fiscais, setArquivos_Notas_Fiscais] = useState([]);
  const [arquivos_canhotos, setArquivosCanhotos] = useState([]);
  const [arquivos_informes, setArquivos_Informes] = useState([]);

  const [operationType, setOperationType] = useState("");
  const [openDuplicatas, setOpenDuplicatas] = useState(false);
  const [openCheques, setOpenCheques] = useState(false);
  const [openContrato, setOpenContratos] = useState(false);

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const database = getDatabase();
        const url = `/cedentes/`;
        const cedentesRef = dbRef(database, url);
        const snapshot = await get(cedentesRef);
        const cedentesData = snapshot.val();
        const nomes = Object.values(cedentesData).map(
          (cedente) => cedente.cedente
        );
        setCedentes(nomes);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [operationType]);

  const handleCloseOtherOperations = () => {
    try {
      if (operationType === "cheque") setOpenCheques(false);
      setOperationType("");
      if (operationType === "contrato") {
        setOpenContratos(false);
        setOperationType("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleCloseDuplicatas = () => {
    try {
      setOpenDuplicatas(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleOpenDuplicatas = () => {
    setOpenDuplicatas(true);
  };

  const handleSubmit = async (operation) => {
    // aqui você pode adicionar a lógica para lidar com os dados do formulário
    setLoading(true);
    const dataFormatada = data.replace(/-/g, "/");
    const fileType = [
      "contratos",
      "copia-cheque",
      "comprovantes-ted",
      "notas-promissorias",
    ];

    const database = getDatabase();
    const operationID = uuidv4();
    const storage = getStorage();

    if (operation === "cheque") {
      const arquivos_pendentes = [];
      setSuccessMessage("");
      setErrorMessage("");

      if (arquivos_contrato.length === 0) {
        arquivos_pendentes.push("Contrato");
      }

      if (arquivos_nota_promissoria.length === 0) {
        arquivos_pendentes.push("Notas Promissórias");
      }

      if (arquivos_cheques.length === 0) {
        arquivos_pendentes.push("Cópia Cheque");
      }

      if (arquivos_comprovantes.length === 0) {
        arquivos_pendentes.push("Comprovantes");
      }
      const duplicataRef = dbRef(
        database,
        `operacoes/${operation}/${dataFormatada}/${operationID}`
      );
      try {
        set(duplicataRef, {
          data: dataFormatada,
          numero_da_operacao: numeroOperacao,
          cedente: cedente,
          arquivos_pendentes: arquivos_pendentes,
        });

        for (let i = 0; i < arquivos_contrato.length; i++) {
          const storageRef = ref(
            storage,
            `cheques/${dataFormatada}/${operationID}/${fileType[0]}/${arquivos_contrato[i].name}`
          );
          const metadata = {
            contentType: arquivos_contrato[i].type,
          };
          await uploadBytes(storageRef, arquivos_contrato[i], metadata);
        }

        for (let i = 0; i < arquivos_comprovantes.length; i++) {
          const storageRef = ref(
            storage,
            `cheques/${dataFormatada}/${operationID}/${fileType[2]}/${arquivos_comprovantes[i].name}`
          );
          const metadata = {
            contentType: arquivos_comprovantes[i].type,
          };
          await uploadBytes(storageRef, arquivos_comprovantes[i], metadata);
        }

        for (let i = 0; i < arquivos_nota_promissoria.length; i++) {
          const storageRef = ref(
            storage,
            `cheques/${dataFormatada}/${operationID}/${fileType[3]}/${arquivos_nota_promissoria[i].name}`
          );
          const metadata = {
            contentType: arquivos_nota_promissoria[i].type,
          };
          await uploadBytes(storageRef, arquivos_nota_promissoria[i], metadata);
        }

        for (let i = 0; i < arquivos_cheques.length; i++) {
          const storageRef = ref(
            storage,
            `cheques/${dataFormatada}/${operationID}/${fileType[1]}/${arquivos_cheques[i].name}`
          );
          const metadata = {
            contentType: arquivos_cheques[i].type,
          };
          await uploadBytes(storageRef, arquivos_cheques[i], metadata);
        }

        setSuccessMessage("Operação cadastrada com sucesso!");
        setCedente("");
        setNumeroOperacao("");
        setData("");
        setArquivos_Comprovantes([]);
        setArquivos_Contrato([]);
        setArquivos_Nota_Promissoria([]);
        setArquivos_Cheques([]);
      } catch (error) {
        console.error(error);
        setErrorMessage(
          "Ocorreu um erro ao cadastrar a operação. Por favor, tente novamente."
        );
      } finally {
        setLoading(false); // Finaliza o loading
      }
    }
    if (operation === "contrato") {
      const arquivos_pendentes = [];
      setSuccessMessage("");
      setErrorMessage("");

      if (arquivos_contrato.length === 0) {
        arquivos_pendentes.push("Contrato");
      }

      if (arquivos_nota_promissoria.length === 0) {
        arquivos_pendentes.push("Notas Promissórias");
      }

      if (arquivos_copia_contrato.length === 0) {
        arquivos_pendentes.push("Cópia Contrato");
      }

      if (arquivos_comprovantes.length === 0) {
        arquivos_pendentes.push("Comprovantes");
      }
      const duplicataRef = dbRef(
        database,
        `operacoes/${operation}/${dataFormatada}/${operationID}`
      );
      try {
        set(duplicataRef, {
          data: dataFormatada,
          numero_da_operacao: numeroOperacao,
          cedente: cedente,
          arquivos_pendentes: arquivos_pendentes,
        });

        for (let i = 0; i < arquivos_contrato.length; i++) {
          const storageRef = ref(
            storage,
            `contratos/${dataFormatada}/${operationID}/${fileType[0]}/${arquivos_contrato[i].name}`
          );
          const metadata = {
            contentType: arquivos_contrato[i].type,
          };
          await uploadBytes(storageRef, arquivos_contrato[i], metadata);
        }

        for (let i = 0; i < arquivos_comprovantes.length; i++) {
          const storageRef = ref(
            storage,
            `contratos/${dataFormatada}/${operationID}/${fileType[2]}/${arquivos_comprovantes[i].name}`
          );
          const metadata = {
            contentType: arquivos_comprovantes[i].type,
          };
          await uploadBytes(storageRef, arquivos_comprovantes[i], metadata);
        }

        for (let i = 0; i < arquivos_nota_promissoria.length; i++) {
          const storageRef = ref(
            storage,
            `contratos/${dataFormatada}/${operationID}/${fileType[3]}/${arquivos_nota_promissoria[i].name}`
          );
          const metadata = {
            contentType: arquivos_nota_promissoria[i].type,
          };
          await uploadBytes(storageRef, arquivos_nota_promissoria[i], metadata);
        }

        for (let i = 0; i < arquivos_copia_contrato.length; i++) {
          const storageRef = ref(
            storage,
            `contratos/${dataFormatada}/${operationID}/${fileType[1]}/${arquivos_copia_contrato[i].name}`
          );
          const metadata = {
            contentType: arquivos_copia_contrato[i].type,
          };
          await uploadBytes(storageRef, arquivos_copia_contrato[i], metadata);
        }

        setSuccessMessage("Operação cadastrada com sucesso!");
        setCedente("");
        setNumeroOperacao("");
        setData("");
        setArquivos_Comprovantes([]);
        setArquivos_Contrato([]);
        setArquivos_Nota_Promissoria([]);
        setArquivos_Copia_Contrato([]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Finaliza o loading
      }
    }
  };

  const handleSubmitDuplicatas = async () => {
    setLoading(true); // Inicia o loading

    // aqui você pode adicionar a lógica para lidar com os dados do formulário
    const dataFormatada = data.replace(/-/g, "/");
    const storage = getStorage();
    const database = getDatabase();
    const duplicataID = uuidv4();
    const duplicataRef = dbRef(
      database,
      `operacoes/duplicatas/${dataFormatada}/${duplicataID}`
    );
    const fileType = [
      "contratos",
      "notas-fiscais",
      "comprovantes-ted",
      "notas-promissorias",
      "canhotos",
      "informes-de-transporte",
    ];
    const arquivos_pendentes = [];
    setSuccessMessage("");
    setErrorMessage("");

    if (arquivos_contrato.length === 0) {
      arquivos_pendentes.push("Contrato");
    }

    if (arquivos_nota_promissoria.length === 0) {
      arquivos_pendentes.push("Notas Promissórias");
    }

    if (arquivos_notas_fiscais.length === 0) {
      arquivos_pendentes.push("Notas Fiscais");
    }

    if (arquivos_comprovantes.length === 0) {
      arquivos_pendentes.push("Comprovantes");
    }
    if (arquivos_canhotos.length === 0) {
      arquivos_pendentes.push("Canhotos");
    }
    if (arquivos_informes.length === 0) {
      arquivos_pendentes.push("Informes de Transporte");
    }

    try {
      set(duplicataRef, {
        data: dataFormatada,
        numero_da_operacao: numeroOperacao,
        cedente: cedente,
        pendencias: pendencias,
        arquivos_pendentes: arquivos_pendentes,
      });

      for (let i = 0; i < arquivos_contrato.length; i++) {
        const storageRef = ref(
          storage,
          `duplicatas/${dataFormatada}/${duplicataID}/${fileType[0]}/${arquivos_contrato[i].name}`
        );
        const metadata = {
          contentType: arquivos_contrato[i].type,
        };
        await uploadBytes(storageRef, arquivos_contrato[i], metadata);
      }

      for (let i = 0; i < arquivos_comprovantes.length; i++) {
        const storageRef = ref(
          storage,
          `duplicatas/${dataFormatada}/${duplicataID}/${fileType[2]}/${arquivos_comprovantes[i].name}`
        );
        const metadata = {
          contentType: arquivos_comprovantes[i].type,
        };
        await uploadBytes(storageRef, arquivos_comprovantes[i], metadata);
      }

      for (let i = 0; i < arquivos_nota_promissoria.length; i++) {
        const storageRef = ref(
          storage,
          `duplicatas/${dataFormatada}/${duplicataID}/${fileType[3]}/${arquivos_nota_promissoria[i].name}`
        );
        const metadata = {
          contentType: arquivos_nota_promissoria[i].type,
        };
        await uploadBytes(storageRef, arquivos_nota_promissoria[i], metadata);
      }

      for (let i = 0; i < arquivos_notas_fiscais.length; i++) {
        const storageRef = ref(
          storage,
          `duplicatas/${dataFormatada}/${duplicataID}/${fileType[1]}/${arquivos_notas_fiscais[i].name}`
        );
        const metadata = {
          contentType: arquivos_notas_fiscais[i].type,
        };
        await uploadBytes(storageRef, arquivos_notas_fiscais[i], metadata);
      }

      for (let i = 0; i < arquivos_canhotos.length; i++) {
        const storageRef = ref(
          storage,
          `duplicatas/${dataFormatada}/${duplicataID}/${fileType[4]}/${arquivos_canhotos[i].name}`
        );
        const metadata = {
          contentType: arquivos_canhotos[i].type,
        };
        await uploadBytes(storageRef, arquivos_canhotos[i], metadata);
      }
      for (let i = 0; i < arquivos_informes.length; i++) {
        const storageRef = ref(
          storage,
          `duplicatas/${dataFormatada}/${duplicataID}/${fileType[5]}/${arquivos_informes[i].name}`
        );
        const metadata = {
          contentType: arquivos_informes[i].type,
        };
        await uploadBytes(storageRef, arquivos_informes[i], metadata);
      }

      setSuccessMessage("Operação cadastrada com sucesso!");
      setCedente("");
      setNumeroOperacao("");
      setData("");
      setPendencias([]);
      setArquivos_Comprovantes([]);
      setArquivos_Contrato([]);
      setArquivos_Nota_Promissoria([]);
      setArquivos_Notas_Fiscais([]);
      setArquivosCanhotos([]);
      setArquivos_Informes([]);
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "Ocorreu um erro ao cadastrar a operação. Por favor, tente novamente."
      );
    } finally {
      setLoading(false); // Finaliza o loading
    }
  };

  const handleSnackbarClose = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  //TRATAMENTO DOS ARQUIVOS NO INPUT
  const notasPromissoriasSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Nota_Promissoria((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };
  const contratosSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Contrato((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };
  const copia_contratosSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Copia_Contrato((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const chequeSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Cheques((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const comprovantesSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Comprovantes((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  const notasFiscaisSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Notas_Fiscais((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };
  const canhotosSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivosCanhotos((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };
  const informesSelect = (event) => {
    const fileList = event.target.files;
    const novosArquivos = Array.from(fileList);
    setArquivos_Informes((arquivosAntigos) => [
      ...arquivosAntigos,
      ...novosArquivos,
    ]);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      height="100vh"
      width="100%"
      flexWrap="wrap"
    >
      <Box sx={{ width: "100%" }}>
        <ResponsiveAppBar />
      </Box>
      <OperacoesContainer duplicatasModal={handleOpenDuplicatas} />

      {
        //MODAL DUPLICATAS MODALDUPLICATAS MODALDUPLICATAS
      }
      <Dialog open={openDuplicatas} onClose={() => handleCloseDuplicatas()}>
        <DialogTitle>
          {!loading ? "Cadastrar Operação" : "Enviando arquivos, aguarde..."}
        </DialogTitle>

        <DialogContent>
          <Snackbar
            open={!!successMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              variant="filled"
            >
              {successMessage}
            </Alert>
          </Snackbar>

          {/* Renderiza o aviso de erro */}
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              variant="filled"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          {!loading ? (
            <>
              <TextField
                type="date"
                value={data}
                size="small"
                onChange={(e) => setData(e.target.value)}
                sx={{ marginBottom: 2 }}
                fullWidth
              />
              <TextField
                label="Número da operação"
                value={numeroOperacao}
                size="small"
                onChange={(e) => setNumeroOperacao(e.target.value)}
                sx={{ marginBottom: 2 }}
                fullWidth
              />
              <Select
                fullWidth
                label="Selecione a empresa cedente"
                onChange={(event) => setCedente(event.target.value)}
              >
                {cedentes.map((nome) => (
                  <MenuItem key={nome} value={nome}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>

              <InputLabel htmlFor="contrato-aditivo">
                Contrato Aditivo
              </InputLabel>
              <Input
                type="file"
                id="contrato-aditivo"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={contratosSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="nota-promissoria">
                Nota Promissória
              </InputLabel>
              <Input
                type="file"
                id="nota-promissoria"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={notasPromissoriasSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="comprovantes-ted">
                Comprovantes TED
              </InputLabel>
              <Input
                type="file"
                id="comprovantes-ted"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={comprovantesSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="notas-fiscais">Notas Fiscais</InputLabel>
              <Input
                type="file"
                id="notas-fiscais"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={notasFiscaisSelect}
                inputProps={{ multiple: true }}
                size="small"
              />
              <InputLabel htmlFor="canhotos">Canhotos</InputLabel>
              <Input
                type="file"
                id="canhotos"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={canhotosSelect}
                inputProps={{ multiple: true }}
                size="small"
              />
              <InputLabel htmlFor="informes">Informes de Transporte</InputLabel>
              <Input
                type="file"
                id="informes"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={informesSelect}
                inputProps={{ multiple: true }}
                size="small"
              />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ margin: "0 auto" }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {!loading ? (
            <>
              <Button onClick={() => handleCloseDuplicatas()}>Cancelar</Button>
              <Button onClick={() => handleSubmitDuplicatas()}>
                Cadastrar
              </Button>
            </>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>

      {
        //MODAL OUTRAS OPERAÇÕES - CHEQUE
      }
      <Dialog open={openCheques} onClose={() => handleCloseOtherOperations()}>
        <DialogTitle>
          {!loading ? "Cadastrar Operação" : "Enviando arquivos, aguarde..."}
        </DialogTitle>

        <DialogContent>
          <Snackbar
            open={!!successMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              variant="filled"
            >
              {successMessage}
            </Alert>
          </Snackbar>

          {/* Renderiza o aviso de erro */}
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              variant="filled"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          {!loading ? (
            <>
              <TextField
                type="date"
                value={data}
                size="small"
                onChange={(e) => setData(e.target.value)}
                sx={{ marginBottom: 2 }}
                fullWidth
              />
              <TextField
                label="Número da operação"
                value={numeroOperacao}
                size="small"
                onChange={(e) => setNumeroOperacao(e.target.value)}
                sx={{ marginBottom: 2 }}
                fullWidth
              />
              <Select
                fullWidth
                label="Selecione a empresa cedente"
                onChange={(event) => setCedente(event.target.value)}
              >
                {cedentes.map((nome) => (
                  <MenuItem key={nome} value={nome}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel htmlFor="contrato-aditivo">
                Contrato Aditivo
              </InputLabel>
              <Input
                type="file"
                id="contrato-aditivo"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={contratosSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="nota-promissoria">
                Nota Promissória
              </InputLabel>
              <Input
                type="file"
                id="nota-promissoria"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={notasPromissoriasSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="comprovantes-ted">
                Comprovantes TED
              </InputLabel>
              <Input
                type="file"
                id="comprovantes-ted"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={comprovantesSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="cheque">Cópia dos Cheques</InputLabel>
              <Input
                type="file"
                id="cheque"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={chequeSelect}
                inputProps={{ multiple: true }}
                size="small"
              />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ margin: "0 auto" }} />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {!loading ? (
            <>
              <Button onClick={() => handleCloseOtherOperations()}>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  const operation = "cheque";
                  handleSubmit(operation);
                }}
              >
                Cadastrar
              </Button>
            </>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>

      {
        //MODAL OUTRAS OPERAÇÕES - CONTRATO
      }
      <Dialog open={openContrato} onClose={() => handleCloseOtherOperations()}>
        <DialogTitle>
          {!loading ? "Cadastrar Operação" : "Enviando arquivos, aguarde..."}
        </DialogTitle>

        <DialogContent>
          <Snackbar
            open={!!successMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              variant="filled"
            >
              {successMessage}
            </Alert>
          </Snackbar>

          {/* Renderiza o aviso de erro */}
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              variant="filled"
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          {!loading ? (
            <>
              <TextField
                type="date"
                value={data}
                size="small"
                onChange={(e) => setData(e.target.value)}
                sx={{ marginBottom: 2 }}
                fullWidth
              />
              <TextField
                label="Número da operação"
                value={numeroOperacao}
                size="small"
                onChange={(e) => setNumeroOperacao(e.target.value)}
                sx={{ marginBottom: 2 }}
                fullWidth
              />
              <Select
                fullWidth
                label="Selecione a empresa cedente"
                onChange={(event) => setCedente(event.target.value)}
              >
                {cedentes.map((nome) => (
                  <MenuItem key={nome} value={nome}>
                    {nome}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel htmlFor="contrato-aditivo">
                Contrato Aditivo
              </InputLabel>
              <Input
                type="file"
                id="contrato-aditivo"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={contratosSelect}
                inputProps={{ multiple: true }}
                size="small"
              />
              <InputLabel htmlFor="copia-contrato">Cópia Contrato</InputLabel>
              <Input
                type="file"
                id="copia-contrato"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={copia_contratosSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="nota-promissoria">
                Nota Promissória
              </InputLabel>
              <Input
                type="file"
                id="nota-promissoria"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={notasPromissoriasSelect}
                inputProps={{ multiple: true }}
                size="small"
              />

              <InputLabel htmlFor="comprovantes-ted">
                Comprovantes TED
              </InputLabel>
              <Input
                type="file"
                id="comprovantes-ted"
                sx={{ marginBottom: 2 }}
                fullWidth
                onChange={comprovantesSelect}
                inputProps={{ multiple: true }}
                size="small"
              />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress sx={{ margin: "0 auto" }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {!loading ? (
            <>
              <Button onClick={() => handleCloseOtherOperations()}>
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  const operation = "contrato";
                  handleSubmit(operation);
                }}
              >
                Cadastrar
              </Button>
            </>
          ) : (
            <></>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CadastrarOperacoes;
