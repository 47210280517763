import React, { useState } from "react";
import LoginForm from "./LoginForm";
import { Box } from "@mui/material";

function MultiStepForm() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      <div>
        <Box mt={5}>
          <LoginForm />
        </Box>
        <Box
          mt={2}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        ></Box>
      </div>
    </Box>
  );
}

export default MultiStepForm;
