import { useState } from "react";
import { Button, Popover, Typography, IconButton } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { getDatabase, ref, update } from "firebase/database";

const FinalizarOperacao = (props, { onConfirm, onCancel }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    // Chama a função de callback fornecida nas props
    const operacao = props.operacao;
    if (operacao.arquivos_pendentes.includes("Comprovantes")) {
      const database = getDatabase();
      let tipo = "";

      if (operacao.tipo === "Cheques") {
        tipo = "cheque";
      }
      if (operacao.tipo === "Contratos") {
        tipo = "contrato";
      }
      if (operacao.tipo === "Duplicatas") {
        tipo = "duplicatas";
      }

      const referencia = ref(
        database,
        `operacoes/${tipo}/${operacao.data}/${operacao.id}/`
      );
      update(referencia, { arquivos_pendentes: null })
        .then(() => {
          console.log("Campo removido com sucesso");
          alert("Finalizado com sucesso!");
          setTimeout(window.location.reload(), 1000);
        })
        .catch((error) => {
          console.error("Ocorreu um erro ao remover o campo:", error);
        });
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        Finalizar Operação
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Os comprovantes ainda não foram enviados, confirma a finalização da
          operação?
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "8px",
          }}
        >
          <IconButton onClick={handleConfirm}>
            <Check />
          </IconButton>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </Popover>
    </div>
  );
};

export default FinalizarOperacao;
