import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { getDatabase, ref, get, update } from "firebase/database";
import { Box, CircularProgress, Typography } from "@mui/material";

const SearchCompanies = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [foundCompanies, setFoundCompanies] = useState(null);
  const [loading, setLoading] = useState(false);
  const [transformWidth, setTransformWidth] = useState("90%");
  const [cedente, setCedente] = useState("");
  const [documento, setDocumento] = useState("");
  const [email, setEmail] = useState("");
  const [editisActive, setEditIsActive] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const saveEdit = async () => {
    setIsSaving(!isSaving);

    const database = getDatabase();
    const url_search = `/cedentes/${foundCompanies[0]}/`;
    const cedentesRef = ref(database, url_search);

    setTimeout(() => {
      const updatedFields = {};

      if (cedente) {
        updatedFields.cedente = cedente;
      }

      if (documento) {
        updatedFields.documento = documento;
      }

      if (email) {
        updatedFields.email = email;
      }

      if (Object.keys(updatedFields).length === 0) {
        alert("Preencha os campos necessários");
      } else {
        update(cedentesRef, updatedFields);
        setEditIsActive(false);
        setTransformWidth("90%");
      }

      setIsSaving(false);

      // Limpar as variáveis de estado aqui
      setCedente("");
      setDocumento("");
      setEmail("");
    }, 1000);
  };

  const searchCompanies = async () => {
    console.log(foundCompanies);
    setFoundCompanies();
    setLoading(true);
    setEditIsActive(false);
    try {
      const database = getDatabase();
      const url = `/cedentes/`;
      const cedentesRef = ref(database, url);
      const snapshot = await get(cedentesRef);
      const cedentesData = snapshot.val();
      console.log(cedentesData);

      const cedenteEncontrado = Object.entries(cedentesData).find(
        ([id, cedente]) => cedente.cedente === searchQuery.toUpperCase()
      );
      const documentoEncontrado = Object.entries(cedentesData).find(
        ([id, documento]) => documento.documento === searchQuery
      );

      if (cedenteEncontrado || documentoEncontrado) {
        const idDoCedenteEncontrado = cedenteEncontrado
          ? cedenteEncontrado[0]
          : documentoEncontrado[0];
        setTimeout(() => {
          setLoading(false);
          setFoundCompanies(cedenteEncontrado || documentoEncontrado);
          console.log("ID do cedente encontrado:", idDoCedenteEncontrado);
          // Faça algo com o ID do cedente encontrado aqui
        }, 1000);
      } else {
        setLoading(false);
        console.log("Cedente não encontrado.");
        alert("Cedente não encontrado!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div
      style={{
        width: "70%",
        display: "flex",
        paddingBottom: 35,
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 35,
      }}
    >
      <Box
        sx={{
          width: "90%",
          display: "flex",
          justifyContent: "space-evenly ",
          alignItems: "center",
          padding: 4,
          borderRadius: 5,
          border: "1px solid darkgray",
        }}
      >
        <TextField
          label="Razão Social ou CNPJ"
          value={searchQuery}
          onChange={handleChange}
          fullWidth
        />
        <Button
          variant="contained"
          onClick={searchCompanies}
          sx={{ marginLeft: 5, width: 200, height: 50 }}
        >
          Pesquisar
        </Button>
      </Box>

      {loading ? (
        <CircularProgress
          sx={{ marginTop: 5 }}
          color="primary"
          size={47}
          thickness={7}
        />
      ) : (
        <>
          {foundCompanies && (
            <Box
              sx={{
                width: transformWidth,
                transition: "1s ease",
                padding: 4,
                borderRadius: 5,
                border: "1px solid darkgray",
                marginTop: 2,
              }}
            >
              <div>
                {isSaving ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="primary" size={47} thickness={7} />
                  </div>
                ) : (
                  <>
                    <div>
                      {editisActive ? (
                        <TextField
                          label="Cedente"
                          value={cedente}
                          size="small"
                          fullWidth
                          onChange={(e) => setCedente(e.target.value)}
                          sx={{ marginBottom: 2 }}
                        />
                      ) : (
                        <>
                          <Typography variant="span">Cedente:</Typography>
                          <Typography variant="span" color="primary">
                            {" "}
                            <strong>{foundCompanies[1].cedente}</strong>{" "}
                          </Typography>
                        </>
                      )}{" "}
                    </div>
                    <div>
                      {editisActive ? (
                        <TextField
                          label="Documento"
                          value={documento}
                          size="small"
                          fullWidth
                          onChange={(e) => setDocumento(e.target.value)}
                          sx={{ marginBottom: 2 }}
                        />
                      ) : (
                        <>
                          <Typography variant="span">CNPJ: </Typography>
                          <Typography variant="span" color="primary">
                            {" "}
                            <strong>{foundCompanies[1].documento}</strong>{" "}
                          </Typography>
                        </>
                      )}
                    </div>
                    <div>
                      {editisActive ? (
                        <TextField
                          label="Email"
                          value={email}
                          size="small"
                          fullWidth
                          onChange={(e) => setEmail(e.target.value)}
                          sx={{ marginBottom: 2 }}
                        />
                      ) : (
                        <>
                          <Typography variant="span">Email: </Typography>
                          <Typography variant="span" color="primary">
                            {" "}
                            <strong> {foundCompanies[1].email}</strong>{" "}
                          </Typography>
                        </>
                      )}
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <Button
                        fullWidth
                        sx={{
                          marginLeft: 5,
                          marginRight: 1,
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (transformWidth === "90%") {
                            setTransformWidth("50%");
                          } else if (transformWidth === "50%") {
                            setTransformWidth("90%");
                          }
                          setEditIsActive(!editisActive);
                        }}
                      >
                        Editar informações
                      </Button>
                      {editisActive ? (
                        <Button
                          fullWidth
                          sx={{
                            marginLeft: 5,
                            marginRight: 1,
                          }}
                          variant="outlined"
                          color="primary"
                          onClick={() => saveEdit()}
                        >
                          Salvar Edição
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default SearchCompanies;
