import React from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import MultiStepForm from './Components/MultiStepForm';
import Home from './Pages/Home';
import PrivateRoute from "./Routes/PrivateRoute"
import CadastrarOperacoes from './Pages/Operacoes/CadastrarOperacoes'
import Cadastros from './Pages/Cadastros';
import Conta from './Pages/Conta';
import ResetPasswordForm from './Components/ResetPasswordForm';
import Cedentes from './Pages/Cedentes';
import EditarCedentes from './Pages/CedentesCrud';
import ListarOperacoes_REFACT from './Pages/Operacoes/ListarOperacoes_REFACT';

const App = () => {

  return (
    <Router>
      <Switch>
        <Route exact path="/login-and-register" component={MultiStepForm} />
        <Route path="/reset-password" component={ResetPasswordForm} />
        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute path="/controle-de-operacoes" component={CadastrarOperacoes} />
        <PrivateRoute path="/novo-cedente" component={Cadastros} />
        <PrivateRoute path="/info-cedentes" component={EditarCedentes} />
        <PrivateRoute path='/cedentes' component={Cedentes} />
        <PrivateRoute path="/conta" component={Conta} />
        <PrivateRoute path="/listar-operacoes" component={ListarOperacoes_REFACT} />
      </Switch>
    </Router>
  );
};

export default App;
