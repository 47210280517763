import { Box, Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import ResponsiveAppBar from "../../Components/ResponsiveAppbar";

function Cedentes() {
  const history = useHistory();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
      }}
    >
      <ResponsiveAppBar />
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ margin: 1 }}
          variant="contained"
          color="primary"
          onClick={() => history.push("/cadastros")}
        >
          Cadastrar Cedente
        </Button>
        <Button
          sx={{ margin: 1 }}
          variant="outlined"
          color="primary"
          onClick={() => history.push("/info-cedentes")}
        >
          Visualizar Cedentes
        </Button>
      </Box>
    </Box>
  );
}

export default Cedentes;
