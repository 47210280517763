// RegistrationForm.js
import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import app from "../Services/Firebase/firebase";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { useHistory } from "react-router-dom";


const RegistrationForm = () => {
    const [empresa, setEmpresa] = useState("")
    const [cnpj, setCnpj] = useState("")
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const auth = getAuth(app);
    const history = useHistory()


    const handleRegistration = () => {
        // Faça a validação dos campos de registro e registre o usuário no Firebase
        // Exemplo:
        if (password !== confirmPassword) {
            alert("As senhas não correspondem!");
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                alert("Registro bem-sucedido!");
                history.push('/')
                // Aqui você pode redirecionar o usuário para a próxima página ou realizar outras ações
            })
            .catch((error) => {
                alert(error.message);
            });


    };

    const handleVerifyCNPJ = (event) => {
        const cnpjNotVerify = event.target.value;
        if (/^\d*$/.test(cnpjNotVerify)) {
            setCnpj(cnpjNotVerify)
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto',
                width: '600px',
            }}>

            <><Box sx={{
                display: "flex",
                flexDirection: 'column',
                width: "100%"
            }}>
                <TextField
                    label="Empresa"
                    value={empresa}
                    onChange={(e) => setEmpresa(e.target.value)} />
                <br />
                <TextField
                    label="CNPJ"
                    value={cnpj}
                    onChange={handleVerifyCNPJ}
                />
                <br />
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <TextField
                    label="Senha"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <br />
                <TextField
                    label="Confirmar senha"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <br />
                <br />
                <Button variant="contained" onClick={handleRegistration}>Finalizar Cadastro</Button>
            </Box>
            </>


        </Box>

    );
};

export default RegistrationForm;
