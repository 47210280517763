import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OperacoesContainer = (props) => {
  const history = useHistory();
  const operacoes = ["cheque", "contrato"];
  return (
    <Box
      display="flex"
      justifyContent="center"
      height="90vh"
      width="100%"
      flexDirection="column"
      flexWrap={"wrap"}
    >
      <Box
        sx={{
          width: "100%",
          height: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            width: "65%",
            height: 70,
          }}
          variant="contained"
          color="primary"
          onClick={() => history.push("/listar-operacoes")}
        >
          Listar operações
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        height={100}
        margin={0}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ width: "250px", height: 65, margin: "10px" }}
          onClick={props.duplicatasModal}
        >
          Duplicatas
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ width: "250px", height: 65, margin: "10px" }}
          onClick={() => props.otherOperationsModal(operacoes[0])}
        >
          Cheques
        </Button>
        <Button
          variant="contained"
          color="warning"
          style={{ width: "250px", height: 65, margin: "10px" }}
          onClick={() => props.otherOperationsModal(operacoes[1])}
        >
          Contratos
        </Button>
      </Box>
    </Box>
  );
};

export default OperacoesContainer;
